






import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class MandateSuccess extends Vue {
  public name = "MandateSuccess";

  public successMessage = "Mandate imported successfully";
}
